import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "col-xs-12 col-sm-12 col-md-12 q-pa-sm",
  style: {"background":"#f5f5f5"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FeedPostagem = _resolveComponent("FeedPostagem")!
  const _component_q_page = _resolveComponent("q-page")!

  return (_openBlock(), _createBlock(_component_q_page, { class: "row" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_FeedPostagem, {
          notificacoes: _ctx.notificacoes,
          "notificacoes-fixadas": _ctx.notificacoesFixadas,
          onAtualizarNotificacoes: _ctx.getNotificacoes,
          ref: "feed"
        }, null, 8, ["notificacoes", "notificacoes-fixadas", "onAtualizarNotificacoes"])
      ])
    ]),
    _: 1
  }))
}