
import { defineComponent, ref } from "vue";
import NotificacaoPostagem from "./notificacoes/NotificacaoPostagem.vue";

export default defineComponent({
  components: {
    NotificacaoPostagem
  },
  props: {
    notificacoes: { type: Array },
    notificacoesFixadas: { type: Array },
  },
  setup(props, { emit }) {
    const dontHaveUpdate = ref<boolean>(false);

    const onLoad = (index: number, done: any) => {
      emit("atualizarNotificacoes", index);
      setTimeout(() => {
        done(dontHaveUpdate.value);
      }, 2000);
    };

    return {
      onLoad,
      dontHaveUpdate,
    };
  },
});
